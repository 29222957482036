<template>
  <v-table>
    <v-thead>
      <tr>
        <v-table-th>Nume</v-table-th>
        <v-table-th>Tip</v-table-th>
        <v-table-th>Valoare</v-table-th>
        <v-table-th>Aplicabilitate</v-table-th>
        <v-table-th>Actiuni</v-table-th>
      </tr>
    </v-thead>
    <v-tbody>
      <tr v-for="row in rows" :key="row.id">
        <v-table-td>
          {{ row.name }}
        </v-table-td>
        <v-table-td>
          {{ row.type?.name }}
        </v-table-td>
        <v-table-td>
          {{ row.value }}
        </v-table-td>
        <v-table-td>
          {{ row.applicability?.name }}
        </v-table-td>
        <v-table-td>
          <v-button @click="$emit('destroy:fee', row.id)"> Sterge </v-button>
        </v-table-td>
      </tr>
    </v-tbody>
  </v-table>
</template>

<script>
import VButton from "./ui/VButton.vue";
import VTable from "./ui/VTable.vue";
import VTableTd from "./ui/VTableTd.vue";
import VTableTh from "./ui/VTableTh.vue";
import VTbody from "./ui/VTbody.vue";
import VThead from "./ui/VThead.vue";
export default {
  components: {
    VTableTd,
    VTable,
    VThead,
    VTableTh,
    VTbody,
    VButton,
  },
  props: {
    rows: {
      type: Array,
    },
  },
  emits: ["destroy:fee"],
};
</script>

<style></style>
