<template>
  <router-link class="btn-primary" :to="{ name: 'vendors.fees.create' }">
    <v-button-icon>
      <PlusIcon />
    </v-button-icon>
    Adauga comision
  </router-link>
</template>

<script>
import { PlusIcon } from "@heroicons/vue/solid";
import VButtonIcon from "./ui/VButtonIcon.vue";

export default {
  components: { PlusIcon, VButtonIcon },
};
</script>
