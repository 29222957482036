<template>
  <app-layout>
    <template v-slot:header> Comisioane </template>
    <page-section>
      <vendors-show-tabs current="vendors.fees.index" :id="id" />
    </page-section>
    <page-section>
      <v-card>
        <v-card-header>
          <template v-slot:actions>
            <vendors-fees-index-button-add />
          </template>
        </v-card-header>
        <loader v-if="loading" class="my-5" />
        <div v-else-if="totalRows">
          <vendors-fees-index-table
            :rows="rows"
            @destroy:fee="destroyFee($event)"
          />
          <pagination
            @change:page="index($event)"
            :total="meta.total"
            :current_page="meta.current_page"
            :from="meta.from"
            :to="meta.to"
            :next_page_url="links.next"
            :prev_page_url="links.prev"
          />
        </div>
        <no-results v-else class="m-5" />
      </v-card>
    </page-section>
  </app-layout>
</template>

<script>
import vendorsFeesApi from "@/api/resources/vendorsFeesApi";
import VCard from "../components/ui/VCard.vue";
import AppLayout from "../layout/AppLayout.vue";
import Pagination from "../components/Pagination.vue";
import VendorsFeesIndexButtonAdd from "../components/VendorsFeesIndexButtonAdd.vue";
import VendorsFeesIndexTable from "../components/VendorsFeesIndexTable.vue";
import Loader from "../components/Loader.vue";
import NoResults from "../components/NoResults.vue";

import PageSection from "../components/ui/PageSection.vue";
import VCardHeader from "../components/ui/VCardHeader.vue";
import VendorsShowTabs from "../components/VendorsShowTabs.vue";

export default {
  components: {
    AppLayout,
    VCard,
    Pagination,
    VendorsFeesIndexButtonAdd,
    VendorsFeesIndexTable,
    Loader,
    NoResults,
    PageSection,
    VCardHeader,
    VendorsShowTabs,
  },
  props: ["id"],

  data() {
    return {
      rows: [],
      meta: {},
      links: {},
      loading: true,
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    index(page = null) {
      this.loading = true;

      vendorsFeesApi
        .index(this.id, page && { page })
        .then((data) => {
          this.rows = data.data;
          this.meta = data.meta;
          this.links = data.links;

          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },

    destroyFee(fee) {
      this.loading = true;

      vendorsFeesApi
        .destroy(this.id, fee)
        .then(() => {
          this.index();
          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.index();
  },
};
</script>
